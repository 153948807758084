<template lang="pug">
.sideBarContainer(:style="{width:siderBarWidth+'px'}")
  .menuCategory(v-for="i in allMenuList")
    .categoryTitle(v-if="i.name") {{ i.name }}
    .subMenuList
      .subMenuItem(v-for="  j in i.children" :class="{ isActive: currentPath === j.path }" @click='handleJump(j)') {{ j.name }}

</template>

<script setup>
import { inject, onMounted, ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getCache, updateCache } from '@/provider/cache'

const { fetch, router } = inject('global')
const route = useRoute()

const currentPath = computed(() => route.path)
const allMenuList = ref([])
let siderBarWidth = ref(230)

function handleJump(val) {
  if (val.mainPath) {
    router.push(val.mainPath)
  } else if (val.path) {
    router.push(val.path)
  }
}

function getMenuList() {
  const cacheMenuList = getCache('menu-homebrew')
  if (cacheMenuList && cacheMenuList?.list) {
    allMenuList.value = cacheMenuList
  }
  fetch.get('/basic/config/menu?module=homebrew').then(res => {
    allMenuList.value = res.list
    updateCache('menu-homebrew', res.list)
  })
}

function handleResize(e) {
  let x = 0
  let disX = e.clientX - siderBarWidth.value
  document.onmousemove = e => {
    x = e.clientX - disX
    siderBarWidth.value = x
  }
  document.onmouseup = () => {
    this.finishResize()
  }
}
function finishResize() {
  document.onmousemove = null
  document.onmouseup = null
}

onMounted(() => {
  getMenuList()
})
</script>

<style lang="less" scoped>
@width: 250px;
.sideBarContainer {
  flex-grow: 0;
  flex-shrink: 0;
  overflow-x: hidden;
  overflow-y: auto;
  width: @width;
  min-width: 200px;
  max-width: 50vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  .menuCategory {
    padding: 0 20px;
    margin-bottom: 10px;
    user-select: none;
    .categoryTitle {
      font-size: 18px;
      font-weight: bold;
      display: flex;
      align-items: center;
      &:before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 18px;
        margin-top: 3px;
        margin-right: 5px;
        background: var(--color-primary);
      }
    }
    .subMenuList {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      .subMenuItem {
        width: 150px;
        height: 20px;
        flex-grow: 0;
        flex-shrink: 0;
        cursor: pointer;
        color: #888;
        font-size: 14px;
        margin-bottom: 10px;
        margin-left: 10px;
        display: flex;
        align-items: center;
        transition: all ease 0.3s;
        position: relative;
        &:before {
          content: '';
          display: block;
          position: absolute;
          width: 4px;
          height: 4px;
          bottom: 6px;
          left: -7px;
          border-radius: 50%;
          background-color: var(--color-border);
          transition: all ease 0.3s;
        }
        &:hover,
        &.isActive {
          color: var(--color-primary);
          &:before {
            background-color: var(--color-primary);
          }
        }
        &.isActive {
          font-size: 16px;
        }
      }
    }
  }
  .moveLine {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 4px;
    background: var(--color-background);
    transition: background ease 0.3s;
    &:hover {
      background: var(--color-primary);
      cursor: w-resize;
    }
  }
}
</style>
