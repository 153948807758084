<template lang="pug">
#mainContainer(:class='{ isMobile }')
  comTabBar
  #innerView
    router-view

</template>

<script setup>
import { isMobile } from '@/provider/sys'
import { useRouter } from 'vue-router'
import comSiderbar from '@/component/layout/siderBar.vue'
import comTabBar from '@/component/layout/tabBar.vue'

const router = useRouter()

function onMenuChange(val) {
  if (val.mainPath) {
    window.$mainRouter.push(val.mainPath)
  } else if (val.path) {
    router.push(val.path)
  }
}
</script>

<style lang="less" scoped>
#mainContainer {
  #innerView {
    background: var(--color-background);
    height: 100vh;
    width: 100vw;
    overflow: auto;
    padding-bottom: var(--tabBarHeight);
  }
  &.isMobile {
  }
}
</style>
