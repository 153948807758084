<template lang="pug">
.tabBarContainer(:class='{isMobile:isMobile}' v-if="showBar")
  .item(v-for="i in tabList" @click="handleClick(i)")
    img.icon(:src="i.icon")
    .name {{i.name}}

</template>

<script setup>
import { inject, onMounted, computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import { isMobile } from '@/provider/sys'
import { useRouter } from 'vue-router'

const { router, message } = inject('global')
const route = useRoute()
const Router = useRouter()
const showBar = ref(route.path != '/account/pick')
watch(
  () => Router.currentRoute.value.path,
  val => {
    if (val === '/account/pick') {
      showBar.value = false
    } else {
      showBar.value = true
    }
  }
)

const tabList = ref([
  {
    name: '首页',
    icon: 'https://bm-mall-file.oss-cn-shenzhen.aliyuncs.com/miniapp/market/home.png',
    path: '/home'
  },
  {
    name: '我的',
    icon: 'https://bm-mall-file.oss-cn-shenzhen.aliyuncs.com/miniapp/market/member.png',
    path: '/mine'
  }
])

function handleClick(val) {
  if (route.path !== val.path) {
    router.replace(val.path)
  }
}
</script>

<style lang="less" scoped>
.tabBarContainer {
  z-index: 1000;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: var(--tabBarHeight);
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  background: white;
  justify-content: center;
  box-shadow: 0 0 5px #ddd;
  &.isMobile {
    justify-content: space-around;
    .item {
      flex-basis: 1;
      flex-grow: 1;
    }
  }
  .item {
    .trans();
    flex-basis: 200px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    &:hover {
      background: var(--color-primary);
      color: white;
    }
    .icon {
      // width: 30px;
      max-height: calc(100% - 1.5em);
    }
  }
}
</style>
